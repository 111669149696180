const PUBLIC_LAYOUT = "default";
const APP_DOMAIN = process.env.VUE_APP_DOMAIN || "localhost";

const PROVINCES = [
  "Hà Nội",
  "Đà Nẵng",
  "Hải Phòng",
  "Hồ Chí Minh",
  "Cần Thơ",
  "Other",
];

export { PUBLIC_LAYOUT, APP_DOMAIN, PROVINCES };
